import { updateUserConfigWithValue } from "../../utils/userConfig";

export const CHANGE_SEARCH = "CHANGE_SEARCH";
export const CHANGE_LOCATION = "CHANGE_LOCATION";
export const CHANGE_CAMPAIGN = "CHANGE_CAMPAIGN";
export const CHANGE_ORIGIN_COUNTRIES = "CHANGE_ORIGIN_COUNTRIES";
export const CHANGE_FILTER_TYPE = "CHANGE_FILTER_TYPE";
export const CHANGE_MANUFACTURERS = "CHANGE_MANUFACTURERS";
export const INITIALIZE_SEARCH_OPTIONS = "INITIALIZE_SEARCH_OPTIONS";

export const DEFAULT_CAMPAIGN = "palestine_support";
export const DEFAULT_LOCATION = "eg";
export const DEFAULT_LANG = "ar";

export const searchOptionsReducer = (state, action) => {
  switch (action.type) {
    case CHANGE_SEARCH:
      return { ...state, search: action.payload.search };
    case CHANGE_LOCATION: {
      const location = action.payload.location;
      updateUserConfigWithValue("location", location);
      return { ...state, location };
    }
    case CHANGE_CAMPAIGN: {
      const {
        key: campaignKey,
        campaign_type: campaignType,
        countries,
        manufacturers,
      } = action.payload.campaign;

      // Should update state in all places
      updateUserConfigWithValue("campaignName", campaignKey);
      updateUserConfigWithValue("includes", campaignType === "included");
      updateUserConfigWithValue("countries", countries);
      updateUserConfigWithValue("manufacturers", manufacturers);

      return { ...state, campaign: action.payload.campaign };
    }
    case CHANGE_ORIGIN_COUNTRIES: {
      const originCountries = action.payload.originCountries;
      updateUserConfigWithValue("countries", originCountries);
      return { ...state, originCountries };
    }
    case CHANGE_MANUFACTURERS: {
      const manufacturers = action.payload.manufacturers;
      updateUserConfigWithValue("manufacturers", manufacturers);
      return { ...state, manufacturers };
    }
    case CHANGE_FILTER_TYPE: {
      const includes = action.payload.includes;
      updateUserConfigWithValue("includes", includes);
      return { ...state, includes };
    }
    case INITIALIZE_SEARCH_OPTIONS: {
      const initialState = action.payload.initialState;
      return { ...state, ...initialState };
    }

    default:
      return state;
  }
};
