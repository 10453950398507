import React, { Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const MainLayout = React.lazy(() => import("../layouts/main"));
const Home = React.lazy(() => import("../pages/home"));
const ProductAlternativesPage = React.lazy(() =>
  import("../pages/product_alternatives")
);
const SearchPage = React.lazy(() => import("../pages/search"));
const ProductDetailsPage = React.lazy(() => import("../pages/product_details"));
const AboutPage = React.lazy(() => import("../pages/about"));

export default function Router() {
  return (
    <BrowserRouter>
      <Suspense>
        <Routes>
          <Route path="/" element={<MainLayout isHome />}>
            <Route path="" element={<Home />} />
            <Route path=":locale" element={<Home />} />
          </Route>
          <Route path=":locale/products" element={<MainLayout />}>
            <Route path="" element={<ProductAlternativesPage />} />
            <Route
              path=":product_name/alternatives"
              element={<ProductAlternativesPage />}
            />
            <Route path="search" element={<SearchPage />} />
            <Route
              path=":product_name/details"
              element={<ProductDetailsPage />}
            />
          </Route>
          <Route path=":locale/about" element={<MainLayout />}>
            <Route path="" element={<AboutPage />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
