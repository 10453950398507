import { countriesList } from "../const";
import { services } from "./http";

export const fetchAlternativeProducts = async ({
  search,
  location,
  campaignKey,
  productName,
  originCountries = [],
  manufacturers = [],
  includes,
}) => {
  const service = services.GET_ALTERNATIVE_PRODUCTS;
  const replacements = await service.endpoint({
    search,
    location,
    campaignKey,
    productName,
    originCountries,
    manufacturers,
    includes,
  });
  return replacements;
};

export const fetchProductInfo = async ({ productName }) => {
  const service = services.GET_PRODUCT_INFO;
  const productInfo = await service.endpoint(productName);
  return productInfo;
};

export const fetchLocations = async () => {
  return countriesList;
};

export const fetchCampaigns = async () => {
  const service = services.GET_CAMPAIGNS;
  const campaigns = await service.endpoint();
  return campaigns;
};

export const fetchCampainInfo = async (campaignName) => {
  const service = services.GET_CAMPAIGN;
  const campaignInfo = await service.endpoint(campaignName);
  return campaignInfo;
};

export const fetchManufacturers = async () => {
  const service = services.GET_MANUFACTURERS;
  const manufacturers = await service.endpoint();
  return manufacturers;
};

export const fetchCountryOptions = async () => {
  const service = services.GET_COUNTRY_OPTIONS;
  const countryOptions = await service.endpoint();
  return countryOptions;
};

export const searchProducts = async (keyword) => {
  const service = services.SEARCH_PRODUCTS;
  const products = await service.endpoint(keyword);
  return products;
};

export const autoCompleteProductName = async (keyword) => {
  const service = services.AUTO_COMPLETE_PRODUCT_NAME;
  const products = await service.endpoint(keyword);
  return products;
};

export const fetchFeedbackTypes = async () => {
  const service = services.GET_FEEDBACK_TYPES;
  const types = await service.endpoint();
  return types;
};

export const verifyToken = async (secret, token) => {
  const service = services.VERIFY_CAPTCH_TOKEN;
  const response = await service.endpoint(secret, token);
  return response;
};

export const getCountryList = async () => {
  const service = services.GET_BASE_LOCATION_COUNTRY_LIST;
  const countries = await service.endpoint();
  return countries;
};
