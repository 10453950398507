import CryptoJS from "crypto-js";

export function decrypt(apiResponse) {
  var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_KEY);
  var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_ENCRYPTION_SALT);

  var decryptedValue = CryptoJS.AES.decrypt(apiResponse, key, {
    iv,
  });

  const result = JSON.parse(decryptedValue.toString(CryptoJS.enc.Utf8));

  return result;
}
