export const countriesList = [
  {
    key: "1",
    label: "Egypt",
    code: "eg",
  },
];

export const LOCAL_STORAGE_KEY = "badayli_user_config";
export const PALESTINE_SUPPORT_KEY = "palestine_support";
export const STALE_TIME = 1000 * 60 * 60 * 2; // 2 hours cache
