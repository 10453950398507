const useLocale = () => {
  //TODO: Refactor the getLocale logic
  const locale = window.location.pathname.split("/")[1];
  return {
    lang: locale.split("-")[1],
    location: locale.split("-")[0],
    isRtl: locale.split("-")[1] === "ar",
  };
};

export const isRtl = () => {
  const locale = window.location.pathname.split("/")[1];
  return locale.split("-")[1] === "ar";
};

export default useLocale;
