import { countriesList } from "../const";
import { isRtl } from "../hooks/useLocale";
import { DEFAULT_LOCATION } from "../pages/product_alternatives/searchOptionsReducer";
import { decrypt } from "../utils/decrypt";

export const host = process.env.REACT_APP_BE_HOST;
export const api_version = process.env.REACT_APP_API_VERSION;
export const api_protocol = process.env.REACT_APP_API_PROTOCOL;
export const basic_auth = process.env.REACT_APP_BASIC_AUTH;
export const baseUrl = `${api_protocol}://${host}${api_version}`;

export const services = {
  GET_PRODUCT_INFO: {
    method: "get",
    endpoint: (productName) =>
      http.get(`${baseUrl}/products/${productName}/info`),
  },
  GET_CAMPAIGNS: {
    method: "get",
    endpoint: () => http.get(`${baseUrl}/campaigns`),
  },
  GET_ALTERNATIVE_PRODUCTS: {
    method: "get",
    endpoint: async ({
      location = DEFAULT_LOCATION,
      campaignKey,
      productName,
      originCountries = [],
      manufacturers = [],
      includes,
    }) => {
      const url = new URL(
        `${baseUrl}/products/${productName}/${location}/replacements`
      );

      const params = new URLSearchParams(url.search);

      if (campaignKey) {
        params.set("q[campaign]", campaignKey);
      }

      if (originCountries?.length > 0) {
        params.set(
          "q[countries]",
          originCountries.map((c) => c.code).join(",")
        );
      }

      if (manufacturers?.length > 0) {
        params.set(
          "q[manufacturers]",
          manufacturers.map((m) => m.key).join(",")
        );
      }

      if (includes) {
        params.set("q[type]", "IN");
      }

      url.search = params.toString();

      const productResponse = await http.get(url.href);
      return productResponse?.replacements || [];
    },
  },
  GET_MANUFACTURERS: {
    method: "get",
    endpoint: () => {
      return http.get(`${baseUrl}/manufacturers`);
    },
  },
  GET_COUNTRY_OPTIONS: {
    method: "get",
    endpoint: () => http.get(`${baseUrl}/countries`),
  },
  SEARCH_PRODUCTS: {
    method: "get",
    endpoint: (keyword) => {
      const url = new URL(`${baseUrl}/products/search`);
      const params = new URLSearchParams(url.search);

      if (keyword) {
        params.set("q", keyword);
      }

      url.search = params;

      return http.get(url.href);
    },
  },
  AUTO_COMPLETE_PRODUCT_NAME: {
    method: "get",
    endpoint: (keyword) => {
      const url = new URL(`${baseUrl}/products/search_autocomplete`);
      const params = new URLSearchParams(url.search);

      if (keyword) {
        params.set("q", keyword);
      }

      url.search = params;

      return http.get(url.href);
    },
  },
  GET_CAMPAIGN: {
    method: "get",
    endpoint: (campaignName) => {
      return http.get(`${baseUrl}/campaigns/${campaignName}`);
    },
  },
  SEND_FEEDBACK: {
    method: "post",
    endpoint: (formValues) => {
      return http.post(`${baseUrl}/feedbacks/`, {
        feedback: formValues.values,
        recaptcha_token: formValues.recaptcha_token,
      });
    },
  },
  GET_FEEDBACK_TYPES: {
    method: "get",
    endpoint: () => {
      return http.get(`${baseUrl}/feedback_types/`);
    },
  },
  VERIFY_CAPTCH_TOKEN: {
    method: "post",
    endpoint: (secret, token) => {
      return http.post(`${baseUrl}/verify_token/`, {
        secret,
        token,
      });
    },
  },
  GET_BASE_LOCATION_COUNTRY_LIST: {
    method: "get",
    endpoint: async () => {
      return new Promise((res) => {
        setTimeout(() => {
          res(countriesList);
        }, 0);
      });
    },
  },
};

export const http = {
  get: async function (endpoint) {
    if (isRtl()) {
      const url = new URL(endpoint);
      const params = new URLSearchParams(url.search);
      params.set("locale", "ar");
      url.search = params.toString();
      endpoint = url.href;
    }
    try {
      const resp = await fetch(endpoint, {
        headers: {
          Accept: "*/*",
          Authorization: basic_auth,
        },
      });

      const text = await resp.text();
      const json = decrypt(text);
      // console.log(json);
      return json;
    } catch (e) {}
  },
  post: async function (endpoint, body) {
    try {
      const resp = await fetch(endpoint, {
        method: "post",
        headers: {
          Accept: "*/*",
          Authorization: basic_auth,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });

      const text = await resp.text();
      const json = decrypt(text);
      // console.log(json);
      return json;
    } catch (e) {}
  },
};
